<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Data History'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12" class="mb-2">
              <b-button variant="outline-dark" @click="$router.push('/kualifikasi_las/addhistory')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                stacked="lg"
                show-empty
                responsive
                hover
                :no-local-sorting="true"
                @sort-changed="sortTable"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                @row-clicked="info"
                v-model="currentItems"
                :busy="isBusy"
              >
                <template #thead-top="data">
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>
                      <b-form-input
                        id="filter-1"
                        size="sm"
                        @input="loadData"
                        v-model="search.nama_welder"
                        type="search"
                        placeholder="Cari Nama Welder..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-7"
                        size="sm"
                        @input="loadData"
                        v-model="search.welder_id_at_welder"
                        type="search"
                        placeholder="Cari Welder ID..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <date-picker
                        v-model="search.tanggal"
                        type="date"
                        value-type="YYYY-MM-DD"
                        range
                        input-class="form-control form-control-sm"
                        placeholder="Pilih tanggal dalam range"
                        @change="loadData"
                        @clear="ClearDate"
                      ></date-picker>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-3"
                        size="sm"
                        @input="loadData"
                        v-model="search.id_joint"
                        type="search"
                        placeholder="Cari ID Joint..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-4"
                        size="sm"
                        @input="loadData"
                        v-model="search.wps_number"
                        type="search"
                        placeholder="Cari Nomor WPS..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-5"
                        size="sm"
                        @input="loadData"
                        v-model="search.proses_pengelasan"
                        type="search"
                        placeholder="Cari Proses Pengelasan..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-6"
                        size="sm"
                        @input="loadData"
                        v-model="search.hasil"
                        type="search"
                        placeholder="Cari Hasil..."
                      ></b-form-input>
                    </b-th>
                    <b-th colspan="1"></b-th>
                  </b-tr>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(tanggal)="data">
                  {{ moment(data.item.tanggal) }}
                </template>

                <template #cell(proses_pengelasan)="data">
                  <span v-html="data.item.proses_pengelasan"></span>
                </template>

                <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item-button @click="info(data.item, $event.target)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="$router.push('/kualifikasi_las/edithistory/' + data.item.id_history)"
                    >
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="toDelete(data)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
          <b-modal id="info-modal" size="lg" title="Detail" ok-only @hide="resetInfoModal">
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Tanggal :</b></b-col>
              <b-col>{{ moment(modalInfo.tanggal) }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Nama Welder :</b></b-col>
              <b-col>{{ modalInfo.nama_welder }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>ID Joint :</b></b-col>
              <b-col>{{ modalInfo.id_joint }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Equipment :</b></b-col>
              <b-col><span v-html="modalInfo.equipment"></span></b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Proses :</b></b-col>
              <b-col><span v-html="modalInfo.proses_pengelasan"></span></b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Material :</b></b-col>
              <b-col><span v-html="modalInfo.material"></span></b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Hasil :</b></b-col>
              <b-col><span v-html="modalInfo.hasil"></span></b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Nomor WPS :</b></b-col>
              <b-col>{{ modalInfo.wps_number }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Kawat Las :</b></b-col>
              <b-col><span v-html="modalInfo.kawat_las"></span></b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Diameter:</b></b-col>
              <b-col>{{ `${modalInfo.diameter_max}` }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Tebal:</b></b-col>
              <b-col sm="2">{{ `${modalInfo.tebal_max}` }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>File :</b></b-col>
              <b-col sm="9">
                <b-button
                  variant="outline-info"
                  size="sm"
                  :href="urlStorage + '/images/history/' + modalInfo.foto"
                  target="_blank"
                >
                  <span class="svg-icon svg-icon-sm svg-icon-info">
                    <i class="flaticon-eye text-success"></i>
                  </span>
                  Lihat Foto
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import * as moment from 'moment'
import 'moment/locale/id'

export default {
  data() {
    return {
      sortBy: 'nama_welder',
      sortDesc: false,
      sort_label: 'ASC',
      items: [],
      fields: [
        { key: 'no', label: 'No' },
        { key: 'nama_welder', label: 'Nama Welder', sortable: true },
        { key: 'welder_id_at_welder', label: 'Welder ID', sortable: true },
        {
          key: 'tanggal',
          label: 'Tanggal',
          sortable: true,
        },
        { key: 'id_joint', label: 'ID Joint', sortable: true },
        { key: 'wps_number', label: 'Nomor WPS', sortable: true },
        {
          key: 'proses_pengelasan',
          label: 'Proses Pengelasan',
          sortable: true,
        },
        {
          key: 'hasil',
          label: 'Hasil',
          sortable: true,
        },
        { key: 'actions', label: 'Actions' },
      ],
      currentItems: [],
      search: {
        nama_welder: '',
        welder_id_at_welder: '',
        tanggal: ['', ''],
        id_joint: '',
        wps_number: '',
        proses_pengelasan: '',
        hasil: '',
      },
      modalInfo: {
        tanggal: null,
        nama_welder: null,
        id_joint: null,
        equipment: null,
        proses_pengelasan: null,
        material: null,
        hasil: null,
        wps_number: null,
        kawat_las: null,
        diameter_max: null,
        tebal_max: null,
        foto: null,
      },
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      isBusy: false,
      dialog: false,

      urlStorage: '',
    }
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kualifikasi Las', route: '' },
      { title: 'History' },
    ])
  },
  created() {
    var self = this
    self.urlStorage = ApiService.urlStorage()
    ApiService.get(
      '/kualifikasi_las/history?page=' +
        this.currentPage +
        '&nama_welder=' +
        this.search.nama_welder +
        '&welder_id_at_welder=' +
        this.search.welder_id_at_welder +
        '&tanggal=' +
        this.search.tanggal +
        '&id_joint=' +
        this.search.id_joint +
        '&wps_number=' +
        this.search.wps_number +
        '&proses_pengelasan=' +
        this.search.proses_pengelasan +
        '&hasil=' +
        this.search.hasil +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label
    )
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.items = data.data.data
          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.isBusy = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    moment: function(date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD').format('dddd, LL')
    },
    ClearDate() {
      this.search.tanggal[0] = ''
      this.search.tanggal[1] = ''
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    info(item, button) {
      this.modalInfo.tanggal = item.tanggal
      this.modalInfo.nama_welder = item.nama_welder
      this.modalInfo.id_joint = item.id_joint
      this.modalInfo.equipment = item.equipment
      this.modalInfo.proses_pengelasan = item.proses_pengelasan
      this.modalInfo.material = item.material
      this.modalInfo.hasil = item.hasil
      this.modalInfo.wps_number = item.wps_number
      this.modalInfo.kawat_las = item.kawat_las
      this.modalInfo.diameter_max = item.diameter_max
      this.modalInfo.tebal_max = item.tebal_max
      this.modalInfo.foto = item.foto
      this.$root.$emit('bv::show::modal', 'info-modal', button)
    },
    resetInfoModal() {
      this.modalInfo.tanggal = null
      this.modalInfo.nama_welder = null
      this.modalInfo.id_joint = null
      this.modalInfo.equipment = null
      this.modalInfo.proses_pengelasan = null
      this.modalInfo.material = null
      this.modalInfo.hasil = null
      this.modalInfo.wps_number = null
      this.modalInfo.kawat_las = null
      this.modalInfo.diameter_max = null
      this.modalInfo.tebal_max = null
      this.modalInfo.foto = null
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get(
        '/kualifikasi_las/history?page=' +
          this.currentPage +
          '&nama_welder=' +
          this.search.nama_welder +
          '&welder_id_at_welder=' +
          this.search.welder_id_at_welder +
          '&tanggal_start=' +
          this.search.tanggal[0] +
          '&tanggal_end=' +
          this.search.tanggal[1] +
          '&id_joint=' +
          this.search.id_joint +
          '&wps_number=' +
          this.search.wps_number +
          '&proses_pengelasan=' +
          this.search.proses_pengelasan +
          '&hasil=' +
          this.search.hasil +
          '&sortBy=' +
          this.sortBy +
          '&sortDesc=' +
          this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.items = data.data.data
            self.totalPerPage = data.data.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    toDelete(row) {
      var id = row.item.id_history
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/kualifikasi_las/history/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                self.isBusy = true
                var interval1 = null
                var i = 0
                self.loadData()
                interval1 = setInterval(function() {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
